import { Button } from '@/app/components/ui/Button';
import Divider from '@/app/components/ui/Divider';

import { DialogClose, DialogFooter } from '..';

interface DialogFooterGroupProps {
  readonly footerActionCallback?: () => void;
  readonly footerActionLabel?: string;
  readonly footerActionLoading?: boolean;
  readonly footerCloseCallback?: () => void;
  readonly footerCloseLabel?: string;
  readonly showDivider?: boolean;
}

const DialogFooterGroup = ({
  footerActionCallback,
  footerActionLabel,
  footerActionLoading = false,
  footerCloseCallback,
  footerCloseLabel,
  showDivider,
}: DialogFooterGroupProps) => {
  return (
    <DialogFooter>
      {showDivider && <Divider color="gray-5" className="mb-4" />}
      <div className="flex gap-4 self-end">
        {!!footerCloseCallback && (
          <DialogClose
            asChild
            className="w-fit"
            data-testid={'modal-footer-close'}
          >
            <Button variant="outline" onClick={footerCloseCallback}>
              {footerCloseLabel ?? 'Close'}
            </Button>
          </DialogClose>
        )}
        {!!footerActionCallback && (
          <DialogClose asChild className="w-fit">
            <Button
              onClick={footerActionCallback}
              loading={footerActionLoading}
              data-testid={'modal-footer-action'}
            >
              {footerActionLabel ?? 'Accept'}
            </Button>
          </DialogClose>
        )}
      </div>
    </DialogFooter>
  );
};

export { DialogFooterGroup };
